





















































import { Media } from "@/models/media.model";
import Vue from "vue";
import Component from "vue-class-component";
import Vuetable from "vuetable-2";
import { namespace } from "vuex-class";
import * as _ from "lodash";

const mediaNamespace = namespace("Media");

@Component({ components: { Vuetable } })
export default class MediaView extends Vue {
  $refs!: Vue["$refs"] & {
    mediaTable: Vuetable;
  };
  fields = [{ name: "title" }, { name: "created_at" }, { name: "actions" }];
  isLoading = false;
  searchString = "";
  perPage = 5000;

  @mediaNamespace.Getter("all")
  media!: Media[];

  @mediaNamespace.Action("fetchAll")
  fetchAll!: () => Promise<Media[]>;

  @mediaNamespace.Action("delete")
  delete!: (id: string) => Promise<Media>;

  get locale() {
    return this.$root.$i18n.locale;
  }

  dataManager(sortOrder, pagination) {
    if (this.media.length < 1) return;

    let local = this.media;

    // sortOrder can be empty, so we have to check for that as well
    if (sortOrder.length > 0) {
      local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction);
    }

    pagination = this.$refs.mediaTable.makePagination(
      local.length,
      this.perPage
    );
    let from = pagination.from - 1;
    let to = from + this.perPage;

    return {
      pagination: pagination,
      data: local,
    };
  }
  doSearch() {
    this.doFetchAll();
  }

  async addMedia() {
    this.$router.push({ name: "media-detail-create" });
  }

  async editMedia(media: Media) {
    this.$router.push({ name: "media-detail", params: { id: media.id } });
  }

  async deleteMedia(media: Media) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${media.title}?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.delete(media.id);
    }
    this.isLoading = false;
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  async created() {
    await this.doFetchAll();
  }
}
